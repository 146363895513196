import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {JackpotHistoryReport} from "../../../models/reports/jackpot-history/jackpot-history-report.model";
import {ReportsService} from "../../../services/reports/reports.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {UtilsService} from "../../../services/utils/utils.service";
import {RolesService} from "../../../services/roles/roles.service";
import {RoleState} from "../../../models/roles/role-state.enum";

@Component({
  selector: 'app-jackpot-history-report',
  templateUrl: './jackpot-history-report.component.html',
  styleUrls: ['./jackpot-history-report.component.scss']
})
export class JackpotHistoryReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<JackpotHistoryReport>;
  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public startDate: string | null = null;
  public endDate: string | null  = null;
  public games = [
    {value: "", text: "All"},
    {value: "rocketman", text: "Rocketman"},
    {value: "fastballs", text: "Fast balls"}
  ];
  public jackpotLevels = [
    {value: 0, text: "All"},
    {value: 1, text: "Jackpots"},
    {value: 2, text: "Rocketpot, Grandpot..."},
    {value: 3, text: "Boosterpot, Fastpot..."},
  ];
  public currentGame = this.games[0];
  public currentLevel = this.jackpotLevels[0];

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private rolesService: RolesService) {

    this.translationColumns = reportsService.getJackpotHistoryReportTranslationColumns();
    if (!this.rolesService.isRole(RoleState.OWNER)){
      this.translationColumns.shift();
    }

    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();

    this.fetchReports();
  }

  ngOnInit(): void {
  }

  private fetchReports(): void {
    const game = this.currentGame.value;
    const level = this.currentLevel.value;
    if (!(this.startDate && this.endDate)) {
      return this.handlingService.error('Please select start & end date');
    }
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getJackpotHistoryReport(game, level, this.startDate, this.endDate).subscribe(value => {
      this.dataSource = new MatTableDataSource<JackpotHistoryReport>(value);
      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }
    }, error => {
      this.handlingService.error('Error while fetching jackpot history report data', error)
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  public refreshData(): void {
    if (this.startDate && this.endDate){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports();
    }else {
      this.handlingService.error('Please select start & end date');
    }
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
      if (!this.rolesService.isRole(RoleState.OWNER)){
        this.columns.shift();
      }
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<JackpotHistoryReport>([]);
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }
}

