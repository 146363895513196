import {Injectable} from '@angular/core';
import {RoleState} from "../../models/roles/role-state.enum";
import {Routes} from "../../models/utils/routes.enum";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private role: RoleState = RoleState.VIEWER;

  private ownerRoutes = [
    Routes.DASHBOARD,
    Routes.REPORTS,
    Routes.SUMMARY_REPORT,
    Routes.ROCKETMAN_GAME_REPORT,
    Routes.FASTBALLS_GAME_REPORT,
    Routes.FINANCIAL_REPORT,
    Routes.CURRENCY_REPORT,
    Routes.PLATFORM_SUMMARY_REPORT,
    Routes.ROUND_REPORT,
    Routes.ROCKETMAN_ROUND_REPORT,
    Routes.FASTBALLS_ROUND_REPORT,
    Routes.BONUSING,
    Routes.CASHBACK_REPORT,
    Routes.CASHBACK_PLAYER_REPORT,
    Routes.PROMO_REPORT,
    Routes.PROMO_PLAYER_REPORT,
    Routes.JACKPOT_VALUES_REPORT,
    Routes.JACKPOT_HISTORY_REPORT,
    Routes.EXCHANGE_RATE_REPORT,
    Routes.PLAYERS_REPORT,
    Routes.TOURNAMENTS,
    Routes.MANAGE_TOURNAMENTS,
    Routes.TOURNAMENT_RESULTS,
    Routes.CONFIG,
    Routes.USER_CONFIG,
    Routes.COMPANY_CONFIG,
    Routes.COMPANY_OVERVIEW_CONFIG,
    Routes.COMPANY_JACKPOT_CONFIG,
    Routes.ROCKETMAN_COMPANY_JACKPOT_CONFIG,
    Routes.FASTBALLS_COMPANY_JACKPOT_CONFIG,
    Routes.COMPANY_CURRENCIES_CONFIG,
    Routes.PROMO_MANAGEMENT,
    Routes.CHAT_ROOMS,
    Routes.INDECENT_USERS,
    Routes.PLAYER_AUDIT,
  ];

  private supportRoutes = [
    Routes.REPORTS,
    Routes.ROUND_REPORT,
    Routes.ROCKETMAN_ROUND_REPORT,
    Routes.FASTBALLS_ROUND_REPORT,
    Routes.BONUSING,
    Routes.PROMO_PLAYER_REPORT,
    Routes.JACKPOT_VALUES_REPORT,
    Routes.EXCHANGE_RATE_REPORT,
    Routes.PLAYERS_REPORT,
    Routes.TOURNAMENTS,
    Routes.MANAGE_TOURNAMENTS,
    Routes.TOURNAMENT_RESULTS,
    Routes.CONFIG,
    Routes.COMPANY_CONFIG,
    Routes.COMPANY_OVERVIEW_CONFIG,
    Routes.COMPANY_JACKPOT_CONFIG,
    Routes.ROCKETMAN_COMPANY_JACKPOT_CONFIG,
    Routes.FASTBALLS_COMPANY_JACKPOT_CONFIG,
    Routes.COMPANY_CURRENCIES_CONFIG,
    Routes.CHAT_ROOMS,
    Routes.INDECENT_USERS,
    Routes.PLAYER_AUDIT,
  ];

  private adminRoutes = [
    Routes.DASHBOARD,
    Routes.REPORTS,
    Routes.ROCKETMAN_GAME_REPORT,
    Routes.FASTBALLS_GAME_REPORT,
    Routes.FINANCIAL_REPORT,
    Routes.PLATFORM_SUMMARY_REPORT,
    Routes.ROUND_REPORT,
    Routes.ROCKETMAN_ROUND_REPORT,
    Routes.FASTBALLS_ROUND_REPORT,
    Routes.BONUSING,
    Routes.CASHBACK_REPORT,
    Routes.CASHBACK_PLAYER_REPORT,
    Routes.PROMO_REPORT,
    Routes.PROMO_PLAYER_REPORT,
    Routes.JACKPOT_VALUES_REPORT,
    Routes.JACKPOT_HISTORY_REPORT,
    Routes.EXCHANGE_RATE_REPORT,
    Routes.PLAYERS_REPORT,
    Routes.TOURNAMENTS,
    Routes.MANAGE_TOURNAMENTS,
    Routes.TOURNAMENT_RESULTS,
    Routes.CONFIG,
    Routes.USER_CONFIG,
    Routes.COMPANY_CONFIG,
    Routes.COMPANY_OVERVIEW_CONFIG,
    Routes.COMPANY_JACKPOT_CONFIG,
    Routes.ROCKETMAN_COMPANY_JACKPOT_CONFIG,
    Routes.FASTBALLS_COMPANY_JACKPOT_CONFIG,
    Routes.COMPANY_CURRENCIES_CONFIG,
    Routes.PROMO_MANAGEMENT,
    Routes.CHAT_ROOMS,
    Routes.INDECENT_USERS,
    Routes.PLAYER_AUDIT,
  ];

  private viewerRoutes = [
    Routes.DASHBOARD,
    Routes.REPORTS,
    Routes.FINANCIAL_REPORT,
    Routes.PLATFORM_SUMMARY_REPORT,
    Routes.ROUND_REPORT,
    Routes.ROCKETMAN_ROUND_REPORT,
    Routes.FASTBALLS_ROUND_REPORT,
    Routes.BONUSING,
    Routes.CASHBACK_REPORT,
    Routes.CASHBACK_PLAYER_REPORT,
    Routes.PROMO_REPORT,
    Routes.PROMO_PLAYER_REPORT,
    Routes.JACKPOT_VALUES_REPORT,
    Routes.EXCHANGE_RATE_REPORT,
    Routes.PLAYERS_REPORT,
    Routes.CHAT_ROOMS,
    Routes.INDECENT_USERS,
  ];

  private roleRoutesMap = {
    4: this.ownerRoutes,
    3: this.supportRoutes,
    2: this.adminRoutes,
    1: this.viewerRoutes,
  }

  constructor() { }

  public setRole(role: RoleState): void {
    this.role = role;
  }

  public checkRole(role: RoleState): boolean {
    return this.role >= role;
  }

  public isRole(role: RoleState): boolean {
    return this.role === role;
  }

  public getRole(): RoleState {
    return this.role;
  }

  /*
    returns -1 if I'm lower role than requested
    returns 1 if I'm greater role than requested
    returns 0 if I'm equal role with requested
   */
  public checkEqualRole(role: RoleState): number {
    if (this.checkRole(role)){
      if (this.role === role) {
        return 0
      }
      return 1;
    }
    return -1;
  }

  public getAvailableRoles(): string[] {
    return ['Support', 'Admin', 'Viewer'];
  }

  public checkRouteAvailability(route: Routes): boolean {
    return this.roleRoutesMap[this.role].indexOf(route) > - 1;
  }

}
