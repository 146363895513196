import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { LoginComponent } from './components/login/login.component';
import { ReportsComponent } from './components/reports/reports.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationItemComponent } from './components/navigation/navigation-item/navigation-item.component';
import { NavbarComponent } from './components/navigation/navbar/navbar.component';
import { SidenavComponent } from './components/navigation/sidenav/sidenav.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { FinancialReportComponent } from './components/reports/financial-report/financial-report.component';
import { GameReportComponent } from './components/reports/round-reports/game-report/game-report.component';
import { PromoReportComponent } from './components/reports/promo-report/promo-report.component';
import { CurrenciesConfigComponent } from './components/configurations/company/currencies-config/currencies-config.component';
import { DashboardChart1Component } from './components/dashboard/dashboard-chart1/dashboard-chart1.component';
import { DashboardChart2Component } from './components/dashboard/dashboard-chart2/dashboard-chart2.component';
import { DashboardChart3Component } from './components/dashboard/dashboard-chart3/dashboard-chart3.component';
import { DashboardChart4Component } from './components/dashboard/dashboard-chart4/dashboard-chart4.component';
import { DashboardChart5Component } from './components/dashboard/dashboard-chart5/dashboard-chart5.component';
import { ExchangeRatesReportComponent } from './components/reports/exchange-rates-report/exchange-rates-report.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserConfigComponent } from './components/configurations/user-config/user-config.component';
import { CompanyConfigComponent } from './components/configurations/company/company-config/company-config.component';
import { JackpotConfigComponent } from './components/configurations/company/jackpot-config/jackpot-config.component';
import { UserTableComponent } from './components/configurations/user-config/user-table/user-table.component';
import { UserProfileComponent } from './components/configurations/user-config/user-profile/user-profile.component';
import { EditUserDialogComponent } from './components/configurations/user-config/edit-user-dialog/edit-user-dialog.component';
import { AddUserDialogComponent } from './components/configurations/user-config/add-user-dialog/add-user-dialog.component';
import { CompaniesTableComponent } from './components/configurations/company/company-config/companies-table/companies-table.component';
import { CompanyEditComponent } from './components/configurations/company/company-config/company-edit/company-edit.component';
import { AddCompanyDialogComponent } from './components/configurations/company/company-config/add-company-dialog/add-company-dialog.component';
import { EditCompanyDialogComponent } from './components/configurations/company/company-config/edit-company-dialog/edit-company-dialog.component';
import { EditJackpotDialogComponent } from './components/configurations/company/jackpot-config/edit-jackpot-dialog/edit-jackpot-dialog.component';
import { PostLoginComponent } from './components/login/post-login/post-login.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import { EditCurrenciesDialogComponent } from './components/configurations/company/currencies-config/edit-currencies-dialog/edit-currencies-dialog.component';
import { SwapCurrenciesDialogComponent } from './components/configurations/company/currencies-config/swap-currencies-dialog/swap-currencies-dialog.component';
import { SuccessDialogComponent } from './components/dialogs/success-dialog/success-dialog.component';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { TwoFactorSignUpComponent } from './components/two-factor-sign-up/two-factor-sign-up.component';
import { FinancialReportDetailsComponent } from './components/reports/financial-report/financial-report-details/financial-report-details.component';
import { GameReportDetailsComponent } from './components/reports/round-reports/game-report/game-report-details/game-report-details.component';
import { DatePickerComponent } from './components/utils/date-picker/date-picker.component';
import { MoveToNextDirective } from './directives/move-to-next/move-to-next.directive';
import { DashboardCardComponent } from './components/dashboard/dashboard-card/dashboard-card.component';
import { DashboardCard2Component } from './components/dashboard/dashboard-card2/dashboard-card2.component';
import { CustomDialogComponent } from './components/dialogs/custom-dialog/custom-dialog.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import { TranslocoRootModule } from "./transloco-root.module";
import { QRCodeModule } from 'angular2-qrcode';

import { registerLocaleData } from '@angular/common';

import localeIt from '@angular/common/locales/it';
import localeRu from '@angular/common/locales/ru';
import localeFr from '@angular/common/locales/fr';
import localeGb from '@angular/common/locales/en-GB';
import localeSr from '@angular/common/locales/sr-Latn';
import { DashboardTimeComponent } from './components/dashboard/dashboard-time/dashboard-time.component';
import { SummaryReportComponent } from './components/reports/summary-report/summary-report.component';
import { SummaryReportDetailsComponent } from './components/reports/summary-report/summary-report-details/summary-report-details.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSortModule} from "@angular/material/sort";
import { PlayersReportComponent } from './components/reports/players-report/players-report.component';
import { PlayerReportSettingsDialogComponent } from './components/reports/players-report/player-report-settings-dialog/player-report-settings-dialog.component';
import { ChatRoomsComponent } from './components/chat-rooms/chat-rooms/chat-rooms.component';
import { ChatRoomItemComponent } from './components/chat-rooms/chat-room-item/chat-room-item.component';
import { ChatRoomPanelComponent } from './components/chat-rooms/chat-room-panel/chat-room-panel.component';
import { ChatRoomMessageComponent } from './components/chat-rooms/chat-room-message/chat-room-message.component';
import {OverlayModule} from "@angular/cdk/overlay";
import { ChatRoomUserPanelComponent } from './components/chat-rooms/chat-room-user-panel/chat-room-user-panel.component';
import { IndecentUsersReportComponent } from './components/chat-rooms/indecent-users-report/indecent-users-report.component';
import { UserHistoryDialogComponent } from './components/chat-rooms/user-history-dialog/user-history-dialog.component';
import { PlayerAuditComponent } from './components/player-audit/player-audit.component';
import { PlayerAuditDetailsComponent } from './components/player-audit/player-audit-details/player-audit-details.component';

import { MatTimepickerModule } from 'mat-timepicker';
import { TimePickerComponent } from './components/utils/time-picker/time-picker.component';
import { PlatformSummaryReportComponent } from './components/reports/platform-summary-report/platform-summary-report.component';
import { CurrencyReportComponent } from './components/reports/currency-report/currency-report.component';
import { CurrencyReportDetailsComponent } from './components/reports/currency-report/currency-report-details/currency-report-details.component';
import { PromoManagementComponent } from './components/promo-management/promo-management.component';
import { PlayerInfoDialogComponent } from './components/promo-management/player-info-dialog/player-info-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { FastballsRoundReportComponent } from './components/reports/round-reports/fastballs-round-report/fastballs-round-report.component';
import { FastballsRoundReportDetailsComponent } from './components/reports/round-reports/fastballs-round-report/fastballs-round-report-details/fastballs-round-report-details.component';
import { PlayerReportDetailsComponent } from './components/reports/players-report/player-report-details/player-report-details.component';
import { ManageTournamentsComponent } from './components/tournaments/manage-tournaments/manage-tournaments.component';
import { TournamentResultsComponent } from './components/tournaments/tournament-results/tournament-results.component';
import { AddTournamentComponent } from './components/tournaments/add-tournament/add-tournament.component';
import { EditTournamentComponent } from './components/tournaments/edit-tournament/edit-tournament.component';
import { CompanyEditorDialogComponent } from './components/tournaments/company-editor-dialog/company-editor-dialog.component';
import { AwardEditorDialogComponent } from './components/tournaments/award-editor-dialog/award-editor-dialog.component';
import { CompanyFilterPipe } from './pipes/company-filter.pipe';
import { AwardItemComponent } from './components/tournaments/award-item/award-item.component';
import { JackpotValuesReportComponent } from './components/reports/jackpot-values-report/jackpot-values-report.component';
import { FastballsJackpotConfigComponent } from './components/configurations/company/jackpot-config/fastballs-jackpot-config/fastballs-jackpot-config.component';
import { RocketmanJackpotConfigComponent } from './components/configurations/company/jackpot-config/rocketman-jackpot-config/rocketman-jackpot-config.component';
import { CompanyMessageDialogComponent } from './components/chat-rooms/company-message-dialog/company-message-dialog.component';
import { ChatRoomUsernameDialogComponent } from './components/chat-rooms/chat-room-username-dialog/chat-room-username-dialog.component';
import { TimePickerInputComponent } from './components/utils/time-picker-input/time-picker-input.component';
import { TimeFormatDirective } from './directives/time-format/time-format.directive';
import { RocketmanGameReportComponent } from './components/reports/game-reports/rocketman-game-report/rocketman-game-report.component';
import { FastballsGameReportComponent } from './components/reports/game-reports/fastballs-game-report/fastballs-game-report.component';
import { JackpotHistoryReportComponent } from './components/reports/jackpot-history-report/jackpot-history-report.component';
import { PromoPlayerReportComponent } from './components/reports/promo-player-report/promo-player-report.component';
import { IsEllipsisDirective } from './directives/is-ellipsis/is-ellipsis.directive';
import { AutocompleteOffDirective } from './directives/autocomplete-off/autocomplete-off.directive';
import { GameInfoComponent } from './components/dialogs/game-info/game-info.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DevelopmentComponent } from './components/utils/development/development.component';
import { CashbackReportComponent } from './components/reports/cashback/cashback-report/cashback-report.component';
import { CashbackPlayerReportComponent } from './components/reports/cashback/cashback-player-report/cashback-player-report.component';
import { CashbackDetailsReportComponent } from './components/reports/cashback/cashback-details-report/cashback-details-report.component';
import { CashbackPlayerDetailsReportComponent } from './components/reports/cashback/cashback-player-details-report/cashback-player-details-report.component';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeGb, 'en-GB');
registerLocaleData(localeSr, 'sr-Latn');


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ConfigurationsComponent,
    LoginComponent,
    ReportsComponent,
    NavigationItemComponent,
    NavbarComponent,
    SidenavComponent,
    MainPageComponent,
    FinancialReportComponent,
    GameReportComponent,
    PromoReportComponent,
    CurrenciesConfigComponent,
    DashboardChart1Component,
    DashboardChart2Component,
    DashboardChart3Component,
    DashboardChart4Component,
    DashboardChart5Component,
    ExchangeRatesReportComponent,
    PageNotFoundComponent,
    UserConfigComponent,
    CompanyConfigComponent,
    JackpotConfigComponent,
    UserTableComponent,
    UserProfileComponent,
    EditUserDialogComponent,
    AddUserDialogComponent,
    CompaniesTableComponent,
    CompanyEditComponent,
    AddCompanyDialogComponent,
    EditCompanyDialogComponent,
    EditJackpotDialogComponent,
    PostLoginComponent,
    ErrorDialogComponent,
    EditCurrenciesDialogComponent,
    SwapCurrenciesDialogComponent,
    SuccessDialogComponent,
    ProfileDialogComponent,
    TwoFactorSignUpComponent,
    FinancialReportDetailsComponent,
    GameReportDetailsComponent,
    DatePickerComponent,
    MoveToNextDirective,
    DashboardCardComponent,
    DashboardCard2Component,
    CustomDialogComponent,
    DashboardTimeComponent,
    SummaryReportComponent,
    SummaryReportDetailsComponent,
    PlayersReportComponent,
    PlayerReportSettingsDialogComponent,
    ChatRoomsComponent,
    ChatRoomItemComponent,
    ChatRoomPanelComponent,
    ChatRoomMessageComponent,
    ChatRoomUserPanelComponent,
    IndecentUsersReportComponent,
    UserHistoryDialogComponent,
    PlayerAuditComponent,
    PlayerAuditDetailsComponent,
    TimePickerComponent,
    PlatformSummaryReportComponent,
    CurrencyReportComponent,
    CurrencyReportDetailsComponent,
    PromoManagementComponent,
    PlayerInfoDialogComponent,
    FastballsRoundReportComponent,
    FastballsRoundReportDetailsComponent,
    PlayerReportDetailsComponent,
    ManageTournamentsComponent,
    TournamentResultsComponent,
    AddTournamentComponent,
    EditTournamentComponent,
    CompanyEditorDialogComponent,
    AwardEditorDialogComponent,
    CompanyFilterPipe,
    AwardItemComponent,
    JackpotValuesReportComponent,
    FastballsJackpotConfigComponent,
    RocketmanJackpotConfigComponent,
    CompanyMessageDialogComponent,
    ChatRoomUsernameDialogComponent,
    TimePickerInputComponent,
    TimeFormatDirective,
    RocketmanGameReportComponent,
    FastballsGameReportComponent,
    JackpotHistoryReportComponent,
    PromoPlayerReportComponent,
    IsEllipsisDirective,
    AutocompleteOffDirective,
    GameInfoComponent,
    SafeHtmlPipe,
    DevelopmentComponent,
    CashbackReportComponent,
    CashbackPlayerReportComponent,
    CashbackDetailsReportComponent,
    CashbackPlayerDetailsReportComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        FormsModule,
        HttpClientModule,
        TranslocoRootModule,
        QRCodeModule,
        MatButtonToggleModule,
        MatSortModule,
        OverlayModule,
        MatTimepickerModule,
        MatTooltipModule
    ],
  exports: [TimeFormatDirective],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
