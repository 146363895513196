import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {ConfigCurrency} from "../../../../../models/configurations/company-config/currency/config-currency.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder} from "@angular/forms";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {LoadingService} from "../../../../../services/loading/loading.service";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {CurrencyAction} from "../../../../../models/configurations/company-config/currency/currency-action.enum";
import {EditCurrenciesDialogComponent} from "../edit-currencies-dialog/edit-currencies-dialog.component";
import {
  CurrencySwapDialogData
} from "../../../../../models/configurations/company-config/currency/currency-swap-dialog-data.interface";
import {LocalizationService} from "../../../../../services/localization/localization.service";

@Component({
  selector: 'app-swap-currencies-dialog',
  templateUrl: './swap-currencies-dialog.component.html',
  styleUrls: ['./swap-currencies-dialog.component.scss']
})
export class SwapCurrenciesDialogComponent implements OnInit, AfterViewInit {

  private readonly backupCurrencies: ConfigCurrency[] = [];
  private deletedCurrencies: ConfigCurrency[] = [];

  @ViewChildren(MatTable) tablesRaw!: QueryList<MatTable<ConfigCurrency>>;
  private tables: MatTable<ConfigCurrency>[] = [];

  public companySelectedCurrency: ConfigCurrency | null = null;
  public globalSelectedCurrency: ConfigCurrency | null = null;

  public companySelectedCurrencies: ConfigCurrency[] = [];
  public globalSelectedCurrencies: ConfigCurrency[] = [];

  public availableCurrencies: ConfigCurrency[] = [];

  public dataSource1!: MatTableDataSource<ConfigCurrency>;
  public dataSource2!: MatTableDataSource<ConfigCurrency>;

  public columns: string[] = [];
  public expanded = false;

  private tableMaxWidth = 645;
  private tableMinWidth = 445;

  public allCurrenciesWidth = this.tableMaxWidth;
  public companyCurrenciesWidth = this.tableMinWidth;

  public game: 'Rocketman' | 'Fast balls';

  constructor(private dialog: MatDialog,
              private dialogRef: MatDialogRef<SwapCurrenciesDialogComponent>,
              private formBuilder: FormBuilder,
              private configurationsService: ConfigurationsService,
              private errorHandlingService: HandlingService,
              private localizationService: LocalizationService,
              private loadingService: LoadingService,
              @Inject(MAT_DIALOG_DATA) public data: CurrencySwapDialogData) {
    this.game = data.gameId === 101 ? 'Rocketman' : 'Fast balls';

    loadingService.setLoadingSteps(1);
    this.backupCurrencies = this.initBackupCurrencies();

    configurationsService.getAllCurrencies(data.gameId).subscribe(data => {
      this.availableCurrencies = configurationsService.currencyInputMapper(data);
      this.dataSource1 = new MatTableDataSource(this.availableCurrencies);
      this.dataSource2 = new MatTableDataSource(this.data.currencies);
      this.columns = this.dataSource1.data[0].columnData
        .filter(item => item.show ? item.show(this.data.gameId) : true)
        .map(item => item.key);
    },error => {
      errorHandlingService.error('Error while fetching all available currencies', error);
      this.dialogRef.close({canceled: this.backupCurrencies});
    }, () => {
      loadingService.incrementLoadingSteps();
    })
  }

  ngOnInit(): void {
  }

  //NEVER USE ARRAY = [] DATASOURCE FAILS TO SEE CHANGE
  ngAfterViewInit() {
    this.tables = this.tablesRaw.toArray();
  }

  public onNoClick(): void {
    this.dialogRef.close({canceled: this.backupCurrencies});
  }

  public save(): void {
    let data = JSON.stringify([...this.deletedCurrencies, ...this.data.currencies], this.jsonReplacer);
    this.dialogRef.close({data: data});
  }

  public expandCollapse(): void {
    this.expanded = !this.expanded;
    this.allCurrenciesWidth = this.allCurrenciesWidth === this.tableMaxWidth ? this.tableMinWidth : this.tableMaxWidth;
    this.companyCurrenciesWidth = this.companyCurrenciesWidth === this.tableMaxWidth ? this.tableMinWidth : this.tableMaxWidth;
  }

  public editCurrency(): void {
    if(this.companySelectedCurrency){
      this.openEditingDialog();
    }
  }

  public transfer(): void {
    if(this.globalSelectedCurrencies.length > 0){
      this.globalSelectedCurrencies.forEach(item => {
        if (!this.checkIfExists(item, this.data.currencies)){
          let copy = this.deepCopyCurrency(item);
          copy.action = CurrencyAction.ADD;
          this.data.currencies.push(copy);
        }
      })
    }
    this.globalSelectedCurrencies = [];
    this.companySelectedCurrencies = [];
    this.tables[1].renderRows();
  }

  public transferAll(): void {
    this.availableCurrencies.forEach(curr => {
      if(!this.checkIfExists(curr, this.data.currencies)){
        let copy = this.deepCopyCurrency(curr);
        copy.action = CurrencyAction.ADD;
        this.data.currencies.push(copy);
      }
    })
    this.tables[1].renderRows();
  }

  public remove(): void {
    if(this.companySelectedCurrencies.length > 0){
      this.companySelectedCurrencies.forEach(item => {
        const index = this.data.currencies.indexOf(item)
        if(index > -1) {
          let deleted = this.data.currencies.splice(index, 1);
          deleted.forEach(item => {
            item.action = CurrencyAction.DELETE;
            if (!this.checkIfExists(item, this.deletedCurrencies)){
              this.deletedCurrencies.push(item);
            }
          })
        }
      })
    }
    this.tables[1].renderRows();
    this.companySelectedCurrency = null;
    this.companySelectedCurrencies = [];
  }

  public removeAll(): void {
    let deleted = this.data.currencies.splice(0, this.data.currencies.length);
    deleted.forEach(item => {
      item.action = CurrencyAction.DELETE;
      if (!this.checkIfExists(item, this.deletedCurrencies)){
        this.deletedCurrencies.push(item);
      }
    })
    this.tables[1].renderRows();
    this.companySelectedCurrency = null;
  }

  public deselectAll(): void {
    this.globalSelectedCurrency = null;
    this.companySelectedCurrency = null;
    this.globalSelectedCurrencies = [];
    this.companySelectedCurrencies = [];
  }

  public selectGlobalCurrency(currency: ConfigCurrency, event: any): void {
    // this.globalSelectedCurrency = currency;
    if (event.ctrlKey){
      if (!this.checkIfExists(currency, this.globalSelectedCurrencies)){
        this.globalSelectedCurrencies.push(currency);
      }else{
        this.deleteCurrencyFromArray(currency, this.globalSelectedCurrencies);
      }
    }else if (this.globalSelectedCurrencies.length === 0){
      this.globalSelectedCurrencies.push(currency);
    }else{
      this.globalSelectedCurrencies = [];
      this.globalSelectedCurrencies.push(currency);
    }
  }

  public selectMyCurrency(currency: ConfigCurrency, event: any): void {
    this.companySelectedCurrency = currency;
    if (event.ctrlKey){
      if (!this.checkIfExists(currency, this.companySelectedCurrencies)){
        this.companySelectedCurrencies.push(currency);
      }else{
        this.deleteCurrencyFromArray(currency, this.companySelectedCurrencies);
      }
    }else if (this.companySelectedCurrencies.length === 0){
      this.companySelectedCurrencies.push(currency);
    }else{
      this.companySelectedCurrencies = [];
      this.companySelectedCurrencies.push(currency);
    }
  }

  public drop(event: CdkDragDrop<MatTableDataSource<ConfigCurrency>, any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data.data,
        event.previousIndex,
        event.currentIndex
      );
      this.tables[1].renderRows();
    } else {
      if(!this.checkIfExists(event.item.data, this.data.currencies)){
        let copy = this.deepCopyCurrency(event.item.data);
        copy.action = CurrencyAction.ADD;
        this.data.currencies.splice(event.currentIndex, 0, copy);
        this.tables[1].renderRows();
      }
    }
  }

  public noReturnPredicate() {
    return false;
  }

  public checkIfExists(currency: ConfigCurrency, array: ConfigCurrency[]): boolean {
    let result = false;

    array.forEach(curr => {
      if(curr.currencyId === currency.currencyId){
        result = true;
        return;
      }
    })

    return result;
  }

  private deleteCurrencyFromArray(currency: ConfigCurrency, array: ConfigCurrency[]): void {
    array.splice(array.indexOf(currency), 1);
  }

  public filterAllCurrencies(event: any): void{
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  public filterCompanyCurrencies(event: any): void{
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  private deepCopyCurrency(input: ConfigCurrency): ConfigCurrency {
    return new ConfigCurrency(
      this.data.companyId,
      input.currencyId,
      input.defaultBet,
      input.minBet,
      input.maxBet,
      input.maxWin,
      input.minBetCombination,
      input.predef1,
      input.predef2,
      input.predef3,
      input.predef4,
      input.predef5 ? input.predef5 : '0',
      input.predef6 ? input.predef6 : '0')
  }

  private openEditingDialog(): void {
    const dialogRef = this.dialog.open(EditCurrenciesDialogComponent, {
      width: '504px',
      data: {
        currency: this.companySelectedCurrency,
        gameId: this.data.gameId
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if (result.data.action !== CurrencyAction.ADD){
          result.data.action = CurrencyAction.EDIT;
        }
        this.refreshElement(result.data);
        this.companySelectedCurrency = null;
        this.tables[1].renderRows();
      }
    })
  }

  private initBackupCurrencies(): ConfigCurrency[] {
    let data: ConfigCurrency[] = [];
    this.data.currencies.forEach(item => {
      data.push(this.deepCopyCurrency(item));
    })
    return data;
  }

  public allCurrenciesHidden(column: string): boolean {
    if (this.columns.indexOf(column) > 4) { return this.expanded; }
    return false;
  }

  public companyCurrenciesHidden(column: string): boolean {
    if (this.columns.indexOf(column) > 4) { return !this.expanded; }
    return false;
  }

  public alignHeaderCell(column: string): string {
    return this.data.currencies[0].align(column);
  }

  private refreshElement(element: ConfigCurrency): void {
    if(this.companySelectedCurrency){
      let currency = new ConfigCurrency(
        element.companyId,
        element.currencyId,
        element.defaultBet,
        element.minBet,
        element.maxBet,
        element.maxWin,
        element.minBetCombination,
        element.predef1,
        element.predef2,
        element.predef3,
        element.predef4,
        element.predef5 ? element.predef5 : '0',
        element.predef6 ? element.predef6 : '0',
        element.action
      )
      let index = this.data.currencies.indexOf(this.companySelectedCurrency);
      this.data.currencies.splice(index, 1);
      this.data.currencies.splice(index, 0, currency);
    }
  }

  private jsonReplacer(key: any, value: any): any {
    if (key === "columnInfo" || key === "columnData"){
      return undefined;
    } else{
      return value;
    }
  }

  public translation(column: string): string {
    if (this.dataSource1.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource1.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.column;
  }

  public format(element: ConfigCurrency, column: string): any {
    const columnMetaData = element.columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.format;
  }

}
