import {ComponentFactoryResolver, ElementRef, Injectable, ViewContainerRef} from '@angular/core';
import {Window} from "../../models/windows/window.interface";
import {ChatRoomsService} from "../chat-rooms/chat-rooms.service";
import {NavigationItem} from "../../models/navigation-item.model";
import {DashboardWindow} from "../../models/windows/dashboard-window.model";
import {FinancialReportWindow} from "../../models/windows/financial-report-window.model";
import {SummaryReportWindow} from "../../models/windows/summary-report-window.model";
import {RocketmanRoundReportWindow} from "../../models/windows/rocketman-round-report-window.model";
import {PromoReportWindow} from "../../models/windows/promo-report-window.model";
import {ExchangeRatesReportWindow} from "../../models/windows/exchange-rates-report-window.model";
import {PlayersReportWindow} from "../../models/windows/players-report-window.model";
import {UserConfigWindow} from "../../models/windows/user-config-window.model";
import {CompanyConfigWindow} from "../../models/windows/company-config-window.model";
import {JackpotConfigWindow} from "../../models/windows/jackpot-config-window.model";
import {CurrenciesConfigWindow} from "../../models/windows/currencies-config-window.model";
import {ChatRoomsWindow} from "../../models/windows/chat-rooms-window.model";
import {IndecentUsersReportWindow} from "../../models/windows/indecent-users-report-window.model";
import {PlayerAuditWindow} from "../../models/windows/player-audit-window.model";
import {PlatformSummaryReportWindow} from "../../models/windows/platform-summary-report-window.model";
import {Routes} from "../../models/utils/routes.enum";
import {CurrencyReportWindow} from "../../models/windows/currency-report-window.model";
import {PromoManagementWindow} from "../../models/windows/promo-management-window.model";
import {BehaviorSubject} from "rxjs";
import {FastballsRoundReportWindow} from "../../models/windows/fastballs-round-report-window.model";
import {ManageTournamentsWindow} from "../../models/windows/manage-tournaments-window.model";
import {TournamentResultsWindow} from "../../models/windows/tournament-results-window.model";
import {JackpotValuesReportWindow} from "../../models/windows/jackpot-values-report-window.model";
import {RocketmanJackpotConfigWindow} from "../../models/windows/rocketman-jackpot-config-window.model";
import {FastballsJackpotConfigWindow} from "../../models/windows/fastballs-jackpot-config-window.model";
import {RocketmanGameReportWindow} from "../../models/windows/rocketman-game-report-window.model";
import {FastballsGameReportWindow} from "../../models/windows/fastballs-game-report-window.model";
import {JackpotHistoryWindow} from "../../models/windows/jackpot-history-window.model";
import {PromoPlayerReportWindow} from "../../models/windows/promo-player-report-window.model";
import {CashbackReportWindow} from "../../models/windows/cashback-report-window.model";
import {CashbackPlayerReportWindow} from "../../models/windows/cashback-player-report-window.model";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public navigationItems = [
    [
      new NavigationItem("Dashboard", Routes.DASHBOARD, "developer_board", new DashboardWindow(),true, []),
      new NavigationItem("Reports", Routes.REPORTS, "library_books", new FinancialReportWindow(),false,[
        new NavigationItem("Summary report", Routes.SUMMARY_REPORT, "analytics", new SummaryReportWindow(),false,[]),
        new NavigationItem("Game reports", Routes.ROCKETMAN_GAME_REPORT, "analytics", new RocketmanGameReportWindow(),false,[
          new NavigationItem("Rocketman - Game report", Routes.ROCKETMAN_GAME_REPORT, "analytics", new RocketmanGameReportWindow(),false, []),
          new NavigationItem("Fast Balls - Game report", Routes.FASTBALLS_GAME_REPORT, "analytics", new FastballsGameReportWindow(),false, []),
        ]),
        new NavigationItem("Financial report", Routes.FINANCIAL_REPORT, "account_balance", new FinancialReportWindow(),false, []),
        new NavigationItem("Currency report", Routes.CURRENCY_REPORT, "euro", new CurrencyReportWindow(),false, []),
        new NavigationItem("Platform summary report", Routes.PLATFORM_SUMMARY_REPORT, "layers", new PlatformSummaryReportWindow(),false, []),
        new NavigationItem("Round reports", Routes.ROUND_REPORT, "sports_esports", new RocketmanRoundReportWindow(),false, [
          new NavigationItem("Rocketman - Round report", Routes.ROCKETMAN_ROUND_REPORT, "payment", new RocketmanRoundReportWindow(),false,[]),
          new NavigationItem("Fast Balls - Round report", Routes.FASTBALLS_ROUND_REPORT, "payment", new FastballsRoundReportWindow(),false,[]),
        ]),
        new NavigationItem("Bonusing", Routes.BONUSING, "credit_card", new CashbackReportWindow(),false,[
          new NavigationItem("Cashback report", Routes.CASHBACK_REPORT, "receipt_long", new CashbackReportWindow(),false,[]),
          new NavigationItem("Cashback player report", Routes.CASHBACK_PLAYER_REPORT, "portrait", new CashbackPlayerReportWindow(),false,[]),
          new NavigationItem("Promo report", Routes.PROMO_REPORT, "payment", new PromoReportWindow(),false,[]),
          new NavigationItem("Promo player report", Routes.PROMO_PLAYER_REPORT, "groups", new PromoPlayerReportWindow(),false,[]),
          new NavigationItem("Jackpot values report", Routes.JACKPOT_VALUES_REPORT, "equalizer", new JackpotValuesReportWindow(),true, []),
          new NavigationItem("Jackpot history report", Routes.JACKPOT_HISTORY_REPORT, "history", new JackpotHistoryWindow(),false, []),
        ]),
        new NavigationItem("Exchange rates report", Routes.EXCHANGE_RATE_REPORT, "loop", new ExchangeRatesReportWindow(),false, []),
        new NavigationItem("Players report", Routes.PLAYERS_REPORT, "people", new PlayersReportWindow(),false, []),
      ]),
      new NavigationItem("Tournaments", Routes.TOURNAMENTS, "emoji_events", new ManageTournamentsWindow(), false, [
          new NavigationItem("Manage tournaments", Routes.MANAGE_TOURNAMENTS, "emoji_events", new ManageTournamentsWindow(), false, []),
          new NavigationItem("Tournament results", Routes.TOURNAMENT_RESULTS, "emoji_events", new TournamentResultsWindow(), false, []),
      ]),
      new NavigationItem("Configurations", Routes.CONFIG,"settings", new UserConfigWindow(),false, [
        new NavigationItem("User", Routes.USER_CONFIG,"person", new UserConfigWindow(),false, []),
        new NavigationItem("Company", Routes.COMPANY_CONFIG,"business", new CompanyConfigWindow(),false, [
          new NavigationItem("Overview", Routes.COMPANY_OVERVIEW_CONFIG,"tune", new CompanyConfigWindow(),false, []),
          new NavigationItem("Jackpot", Routes.COMPANY_JACKPOT_CONFIG,"money", new JackpotConfigWindow(),false, [
            new NavigationItem("Rocketman - Jackpot", Routes.ROCKETMAN_COMPANY_JACKPOT_CONFIG,"money", new RocketmanJackpotConfigWindow(),false, []),
            new NavigationItem("Fast Balls - Jackpot", Routes.FASTBALLS_COMPANY_JACKPOT_CONFIG,"money", new FastballsJackpotConfigWindow(),false, [])
          ]),
          new NavigationItem("Currencies", Routes.COMPANY_CURRENCIES_CONFIG,"euro", new CurrenciesConfigWindow(),false, [])
        ]),
        new NavigationItem("Promo management", Routes.PROMO_MANAGEMENT, "manage_accounts", new PromoManagementWindow(), false, [])
      ])
    ],
    [
      new NavigationItem("Chat rooms", Routes.CHAT_ROOMS,"chat", new ChatRoomsWindow(),false, []),
      new NavigationItem("Indecent users", Routes.INDECENT_USERS,"supervised_user_circle", new IndecentUsersReportWindow(),false, []),
    ],
    [
      new NavigationItem("Player audit", Routes.PLAYER_AUDIT,"person_search", new PlayerAuditWindow(),false, []),
    ]
  ]

  public SIDENAV_OVER = 'over';
  public SIDENAV_PUSH = 'push';
  public SIDENAV_SIDE = 'side';

  private sidenav: any;
  private declare windowContainer: ViewContainerRef;

  private currentRoute = new BehaviorSubject<Routes>(Routes.HOME);

  constructor(private factoryResolver: ComponentFactoryResolver) {
  }

  public setSidenav(sidenav: ElementRef): void {
    this.sidenav = sidenav;
  }

  public setWindowContainer(view: ViewContainerRef): void {
    this.windowContainer = view;
  }

  public setWindow(window: Window | null): void {
    if (this.windowContainer && window){
      this.windowContainer.clear();
      const factory = this.factoryResolver.resolveComponentFactory(window.component);
      this.windowContainer.createComponent(factory);
    }
  }

  public toggleSidenav(): void {
    this.sidenav.toggle();
  }

  public setSidenavMode(mode: string): void {
    this.sidenav.mode = mode;
  }

  public getSidenavItemsLength(): number {
    return this.navigationItems.length;
  }

  public getSidenavItems(index: number): NavigationItem[] {
    return this.navigationItems[index];
  }

  public setCurrentRoute(route: Routes): void {
    this.currentRoute.next(route);
  }

  public getCurrentRoute(): Routes {
    return this.currentRoute.value;
  }

}
