import {Injectable} from '@angular/core';
import {ConfigUser} from "../../models/configurations/user-config/config-user.model";
import {RolesService} from "../roles/roles.service";
import {ConfigCompany} from "../../models/configurations/company-config/overview/config-company.model";
import {ConfigJackpot} from "../../models/configurations/company-config/jackpot/config-jackpot.model";
import {ConfigCurrency} from "../../models/configurations/company-config/currency/config-currency.model";
import {HttpClient} from "@angular/common/http";
import {InputUser} from "../../models/configurations/user-config/mappers/input-user.interface";
import {BehaviorSubject, Observable} from "rxjs";
import {Credentials} from "../../models/user/credentials.model";
import {OutputUserUpdate} from "../../models/configurations/user-config/mappers/output-user-update.interface";
import {CompanySimple} from "../../models/configurations/company-simple.interface";
import {OutputUserInsert} from "../../models/configurations/user-config/mappers/output-user-insert.interface";
import {InputCompany} from "../../models/configurations/company-config/mappers/input-company.interface";
import {OutputCompanyInsert} from "../../models/configurations/company-config/mappers/output-company-insert.interface";
import {OutputCompanyUpdate} from "../../models/configurations/company-config/mappers/output-company-update.interface";
import {InputJackpot} from "../../models/configurations/company-config/mappers/input-jackpot.interface";
import {OutputJackpotUpdate} from "../../models/configurations/company-config/mappers/output-jackpot-update.interface";
import {InputCurrency} from "../../models/configurations/company-config/mappers/input-currency.interface";
import {OutputCurrencyUpdate} from "../../models/configurations/company-config/mappers/output-currency-update.interface";
import {RoleState} from "../../models/roles/role-state.enum";

import { JwtHelperService } from "@auth0/angular-jwt";
import {UnmappedPlatform} from "../../models/configurations/company-config/overview/unmapped-platform.interface";
import {UtilsService} from "../utils/utils.service";
import {environment} from "../../../environments/environment";
import {BillingType} from "../../models/configurations/company-config/overview/billing-type.interface";
import {AbstractControl, ValidationErrors} from "@angular/forms";

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  private username = new BehaviorSubject<string>('');
  public usernameObs = this.username.asObservable();

  private credentials!: Credentials;

  constructor(private rolesService: RolesService,
              private utilsService: UtilsService,
              private http: HttpClient) {
    this.credentials = this.initCredentials();
  }

  //Credentials
  private initCredentials(): Credentials {
    const defaultCredentials: Credentials = {
      username: '',
      token: '',
      authRequired: true,
      companyId: -1,
      showPlatformSummaryReport: false,
      role: 'Viewer',
      info: '',
      secretCode: ''
    }
    const helper = new JwtHelperService();
    let token = localStorage.getItem('token');
    let device = localStorage.getItem('device');
    // if(!device){ return defaultCredentials; }
    if (!token){ return defaultCredentials; }
    const decodedToken = helper.decodeToken(token);
    return {
      username: decodedToken.sub,
      token: token,
      authRequired: false,
      companyId: decodedToken.CompanyId,
      showPlatformSummaryReport: decodedToken.ShowPlatformSummaryReport,
      role: decodedToken.Role,
      info: device ? device : '',
      secretCode: ''
    }
  }

  public setCredentials(credentials: Credentials): void {
    this.credentials = credentials;
    if(credentials.role === 'Owner'){
      this.rolesService.setRole(RoleState.OWNER);
    }else if (credentials.role === 'Support'){
      this.rolesService.setRole(RoleState.SUPPORT);
    }else if (credentials.role === 'Admin'){
      this.rolesService.setRole(RoleState.ADMIN);
    }else{
      this.rolesService.setRole(RoleState.VIEWER);
    }
    if (credentials.info !== null){
      localStorage.setItem('device', credentials.info);
    }
    this.username.next(this.credentials.username);
  }

  public getCredentials(): Credentials {
    return this.credentials;
  }

  public reloadCredentials() {
    const helper = new JwtHelperService();
    let token = localStorage.getItem('token');
    let device = localStorage.getItem('device');
    // if(!device){ return; }
    if (!token){ return; }
    const decodedToken = helper.decodeToken(token);
    this.setCredentials({
      username: decodedToken.sub,
      token: token,
      authRequired: false,
      companyId: decodedToken.CompanyId,
      showPlatformSummaryReport: decodedToken.ShowPlatformSummaryReport,
      role: decodedToken.Role,
      info: device ? device : '',
      secretCode: ''
    });
  }

  public checkPlatform(): boolean {
    return this.getCredentials().showPlatformSummaryReport;
  }

  public getCompanyId(): number {
    return this.credentials.companyId;
  }

  //Users
  public getUsers(): Observable<InputUser[]>{
    const url = baseUrl.concat('users/show');
    return this.http.get<InputUser[]>(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public addUser(user: ConfigUser) {
    const url = baseUrl.concat('users/insert');
    return this.http.post(url, this.userInsertOutputMapper(user), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public updateUser(user: ConfigUser){
    const url = baseUrl.concat('users/update');
    return this.http.post(url, this.userUpdateOutputMapper(user), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public userInputMapper(unmapped: InputUser[]): ConfigUser[]{
    let data: ConfigUser[] = []
    unmapped.forEach(item => {
      data.push(
        new ConfigUser(
          item.ID,
          item.Username,
          '',
          item.Email,
          item.Comment,
          item.IsLockedOut,
          item.CompanyID,
          item.CompanyName,
          item.UserRole,
          item.IsUsing2FA))
    })
    return data;
  }

  private userInsertOutputMapper(user: ConfigUser): OutputUserInsert {
    return {
      username: user.username,
      password: user.password,
      email: user.email,
      comment: user.comment,
      companyId: user.companyId,
      userRole: user.userRole,
    }
  }

  private userUpdateOutputMapper(user: ConfigUser): OutputUserUpdate {

    return {
      id: user.id,
      password: user.password,
      email: user.email,
      comment: user.comment,
      isLockedOut: user.isLockedOut,
      resetSecretCode: user.resetSecretCode
    }
  }

  public updateUserPassword(password: string) {
    const url = baseUrl.concat('users/update/password');
    return this.http.post(url, {
      password: password
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  //Companies
  public getSimpleCompanies(): Observable<CompanySimple[]> {
    const url = baseUrl.concat('company/show');
    return this.http.get<CompanySimple[]>(url, {
      params: {
        forComboBox: 1
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getPlatforms(): Observable<UnmappedPlatform[]>{
    const url = baseUrl.concat('company/platform');
    return this.http.get<UnmappedPlatform[]>(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getCompanies(): Observable<InputCompany[]>{
    const url = baseUrl.concat('company/show');
    return this.http.get<InputCompany[]>(url, {
      params: {
        forComboBox: 0
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public addCompany(company: ConfigCompany) {
    const url = baseUrl.concat('company/insert');
    return this.http.post(url, this.companyInsertOutputMapper(company), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public updateCompany(company: ConfigCompany){
    const url = baseUrl.concat('company/update');
    return this.http.post(url, this.companyUpdateOutputMapper(company), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public companyInputMapper(unmapped: InputCompany[]): ConfigCompany[] {
    let data: ConfigCompany[] = []
    unmapped.forEach(item => {
      data.push(
        new ConfigCompany(
          item.ID,
          item.CompanyName,
          item.PlatformName,
          this.utilsService.utcTimes.getByValue(item.TimeOffset),
          item.UseDST,
          new Date(item.NonBillingPeriod).toLocaleDateString("sv-SE"),
          item.PlatformID,
          item.ExtData,
          item.Customization,
          item.ChatGroupID,
          item.Active,
          item.BillingType,
          item.BillingPercentage,
          item.EOMFX,
          item.UseJackpotL1,
          item.Isolated,
          item.DelUnusedPromo,
          item.DelUnusedFreeTickets,
          item.FixedCurrency,
          item.Description,
          item.PromoWinToBonus,
          '', '',
          item.PayoutDescription))
      })
    return data;
  }

  private companyInsertOutputMapper(company: ConfigCompany): OutputCompanyInsert {
    let useDST = 0;
    if (company.useDST) { useDST = 1; }
    let eomfx = 0;
    if (company.eomfx) { eomfx = 1; }
    let isolated = 0;
    if (company.isolated) { isolated = 1; }
    return {
      companyName: company.companyName,
      timeOffset: company.timeOffset.value,
      useDST: useDST,
      nonBillingPeriod: company.nonBillingPeriod,
      platformId: company.platformID,
      extData: company.extData,
      customization: company.customization,
      billingType: company.billingType,
      billingPercentage: company.billingPerc,
      eomfx: eomfx,
      isolated: isolated,
      delUnusedPromo: company.delUnusedPromo,
      delUnusedFreeTickets: company.delUnusedFreeTickets,
      fixedCurrency: company.fixedCurrency,
      webBackOfficeAdminUser: company.adminUsername,
      webBackOfficeAdminEmail: company.adminEmail,
      description: company.description,
      promoWinToBonus: company.promoWinToBonus
    }
  }

  private companyUpdateOutputMapper(company: ConfigCompany): OutputCompanyUpdate {
    let useDST = 0;
    if (company.useDST) { useDST = 1; }
    let active = 0;
    if (company.active) { active = 1; }
    let eomfx = 0;
    if (company.eomfx) { eomfx = 1; }
    let isolated = 0;
    if (company.isolated) { isolated = 1; }

    return {
      id: company.id,
      companyName: company.companyName,
      timeOffset: company.timeOffset.value,
      useDST: useDST,
      nonBillingPeriod: company.nonBillingPeriod,
      platformId: company.platformID,
      extData: company.extData,
      customization: company.customization,
      chatGroupId: company.chatGroupID,
      active: active,
      billingType: company.billingType,
      billingPercentage: company.billingPerc,
      eomfx: eomfx,
      useJackpot: company.useJackpotL1,
      isolated: isolated,
      delUnusedPromo: company.delUnusedPromo,
      delUnusedFreeTickets: company.delUnusedFreeTickets,
      fixedCurrency: company.fixedCurrency,
      description: company.description,
      promoWinToBonus: company.promoWinToBonus
    }
  }

  //Jackpot
  public getJackpots(gameId: number): Observable<InputJackpot[]> {
    const url = baseUrl.concat('jackpot/config/show');
    return this.http.get<InputJackpot[]>(url, {
      params: {
        gameId: gameId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public updateJackpot(jackpot: ConfigJackpot, gameId: number){
    const url = baseUrl.concat('jackpot/config/update');
    return this.http.post(url, this.jackpotUpdateOutputMapper(jackpot, gameId), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public jackpotInputMapper(unmapped: InputJackpot[]): ConfigJackpot[] {
    let data: ConfigJackpot[] = []
    unmapped.forEach(item => {
      data.push(
        new ConfigJackpot(
          item.ID,
          item.CompanyName,
          item.LevelID,
          item.JackPotName,
          item.JackPotPercentage,
          item.JackPotReservePercentage,
          item.JackPotMinValue,
          item.JackPotMaxValue,
          item.JackPotActive
        )
      )
    })
    return data;
  }

  private jackpotUpdateOutputMapper(jackpot: ConfigJackpot, gameId: number): OutputJackpotUpdate {
    let active = 0;
    if (jackpot.jackPotActive) { active = 1; }

    return {
      id: jackpot.id,
      jackPotPercentage: jackpot.jackPotPercentage,
      jackPotReservePercentage: jackpot.jackPotReservePercentage,
      jackPotMinValue: jackpot.jackPotMinValue,
      jackPotMaxValue: jackpot.jackPotMaxValue,
      jackPotActive: active,
      gameId: gameId
    }
  }

  //Currencies
  /*
      If called as owner for no company, id = -1 -> all available currencies
      If called as owner for some company, id = companyId -> company currencies
      If called as admin for no company, id = -1 -> company currencies
      If called as admin for my company, id = companyId -> company currencies
    */
  public getCompanyCurrencies(gameId: number, companyId?: number): Observable<InputCurrency[]> {
    let id = companyId === undefined ? -1 : companyId;
    const url = baseUrl.concat('currency/show');
    return this.http.get<InputCurrency[]>(url, {
      params: {
        gameId: gameId,
        companyId: id
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getAllCurrencies(gameId: number): Observable<InputCurrency[]> {
    const url = baseUrl.concat('currency/before-update');
    return this.http.get<InputCurrency[]>(url, {
      params: {
        gameId: gameId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public updateCurrencies(jsonData: string, gameId: number): Observable<InputCurrency[]> {
    const url = baseUrl.concat('currency/update/multiple');
    return this.http.post<InputCurrency[]>(url, this.currencyUpdateOutputMapper(jsonData, gameId), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public currencyInputMapper(unmapped: InputCurrency[]): ConfigCurrency[] {
    let data: ConfigCurrency[] = []
    unmapped.forEach(item => {
      data.push(
        new ConfigCurrency(
          item.CompanyID,
          item.CurrencyID,
          item.DefaultBet,
          item.MinBet,
          item.MaxBet,
          item.MaxWin,
          item.MinBetCombination,
          item.Predef1,
          item.Predef2,
          item.Predef3,
          item.Predef4,
          item.Predef5 ? item.Predef5 : '0',
          item.Predef6 ? item.Predef6 : '0')
      )
    })
    return data;
  }

  private currencyUpdateOutputMapper(jsonData: string, gameId: number): OutputCurrencyUpdate {
    return {
      jsonData: jsonData,
      gameId: gameId
    }
  }

  public getBillingTypes(): BillingType[] {
    return [
      {value: 0, text: '0 - Free'},
      {value: 1, text: '1 - Fixed percentage'},
      {value: 2, text: '2 - Floating percentage'},
    ]
  }

  public findBillingType(types: BillingType[], number: number): BillingType | undefined {
    return types.find(type => type.value === number);
  }

  public jsonValidator(control: AbstractControl): ValidationErrors | null {
    try {
      if (typeof control.value === 'string' && control.value.length == 0){
        return null;
      }
      JSON.parse(control.value);
    } catch (e) {
      return { jsonInvalid: true };
    }

    return null;
  };

}
