export enum Routes {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  SUMMARY_REPORT = 'summary-report',
  ROCKETMAN_GAME_REPORT = 'rocketman-game-report',
  FASTBALLS_GAME_REPORT = 'fastballs-game-report',
  FINANCIAL_REPORT = 'financial-report',
  CURRENCY_REPORT = 'currency-report',
  PLATFORM_SUMMARY_REPORT = 'platform-summary-report',
  ROUND_REPORT = 'round-report',
  ROCKETMAN_ROUND_REPORT = 'rocketman-round-report',
  FASTBALLS_ROUND_REPORT = 'fastballs-round-report',
  BONUSING = 'bonusing',
  CASHBACK_REPORT = 'cashback-report',
  CASHBACK_PLAYER_REPORT = 'cashback-player-report',
  PROMO_REPORT = 'promo-report',
  PROMO_PLAYER_REPORT = 'promo-player-report',
  EXCHANGE_RATE_REPORT = 'exchange-rate-report',
  PLAYERS_REPORT = 'players-report',
  JACKPOT_VALUES_REPORT = 'jackpot-values-report',
  JACKPOT_HISTORY_REPORT = 'jackpot-history-report',
  TOURNAMENTS = 'tournaments',
  MANAGE_TOURNAMENTS = 'manage-tournaments',
  TOURNAMENT_RESULTS = 'tournament-results',
  CONFIG = 'config',
  USER_CONFIG = 'user-config',
  COMPANY_CONFIG = 'company-config',
  COMPANY_OVERVIEW_CONFIG = 'company-overview-config',
  COMPANY_JACKPOT_CONFIG = 'company-jackpot-config',
  ROCKETMAN_COMPANY_JACKPOT_CONFIG = 'rocketman-company-jackpot-config',
  FASTBALLS_COMPANY_JACKPOT_CONFIG = 'fastballs-company-jackpot-config',
  COMPANY_CURRENCIES_CONFIG = 'company-currencies-config',
  CHAT_ROOMS = 'chat-rooms',
  INDECENT_USERS = 'indecent-users',
  PLAYER_AUDIT = 'player-audit',
  PROMO_MANAGEMENT = 'promo-management',
}
