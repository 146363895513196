import {Component, Inject, OnInit} from '@angular/core';
import {ConfigCurrency} from "../../../../../models/configurations/company-config/currency/config-currency.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  CurrencyEditDialogData
} from "../../../../../models/configurations/company-config/currency/currency-edit-dialog-data.interface";

@Component({
  selector: 'app-edit-currencies-dialog',
  templateUrl: './edit-currencies-dialog.component.html',
  styleUrls: ['./edit-currencies-dialog.component.scss']
})
export class EditCurrenciesDialogComponent implements OnInit {

  public editingForm: FormGroup;
  private regex: string = '^\\d+(\\.\\d+)?$';
  public game: 'Rocketman' | 'Fast balls';
  public dynamicFormFieldArrayTop: any[] = [];

  constructor(private dialogRef: MatDialogRef<EditCurrenciesDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: CurrencyEditDialogData) {
    this.game = data.gameId === 101 ? 'Rocketman' : 'Fast balls';

    this.editingForm = this.formBuilder.group({
      minBet: [data.currency.minBet, [Validators.required, Validators.min(0)]],
      defaultBet: [data.currency.defaultBet, [Validators.required, Validators.min(0)]],
      minBetCombination: [data.currency.minBetCombination, [Validators.required, Validators.min(0)]],
      maxBet: [data.currency.maxBet, [Validators.required, Validators.min(0)]],
      maxWin: [data.currency.maxWin, [Validators.required, Validators.min(0)]],
      predef1: [data.currency.predef1, [Validators.required, Validators.pattern(this.regex)]],
      predef2: [data.currency.predef2, [Validators.required, Validators.pattern(this.regex)]],
      predef3: [data.currency.predef3, [Validators.required, Validators.pattern(this.regex)]],
      predef4: [data.currency.predef4, [Validators.required, Validators.pattern(this.regex)]],
      predef5: [data.currency.predef5 ? data.currency.predef5 : 0, [Validators.required, Validators.pattern(this.regex)]],
      predef6: [data.currency.predef6 ? data.currency.predef6 : 0, [Validators.required, Validators.pattern(this.regex)]],
    })

    this.dynamicFormFieldArrayTop = [
      {label: 'configurations.company.currencies.tableColumns.minBet', formControlName: 'minBet', formControl: this.editingForm.get('minBet'), errorText: 'Min bet'},
      {label: 'configurations.company.currencies.tableColumns.defaultBet', formControlName: 'defaultBet', formControl: this.editingForm.get('defaultBet'), errorText: 'Default bet'},
      {label: 'configurations.company.currencies.tableColumns.minBetCombination', formControlName: 'minBetCombination', formControl: this.editingForm.get('minBetCombination'), errorText: 'Min bet combination'},
      {label: 'configurations.company.currencies.tableColumns.maxBet', formControlName: 'maxBet', formControl: this.editingForm.get('maxBet'), errorText: 'Max bet'},
      {label: 'configurations.company.currencies.tableColumns.maxWin', formControlName: 'maxWin', formControl: this.editingForm.get('maxWin'), errorText: 'Max win'},
    ]

    if (this.data.gameId === 101) {
      this.dynamicFormFieldArrayTop.splice(2, 1);
    }
  }

  ngOnInit(): void {
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public submitForm(): void {
    if (this.editingForm.invalid){ return; }
    this.data.currency.minBet = this.editingForm.get('minBet')?.value;
    this.data.currency.defaultBet = this.editingForm.get('defaultBet')?.value;
    this.data.currency.minBetCombination = this.editingForm.get('minBetCombination')?.value;
    this.data.currency.maxBet = this.editingForm.get('maxBet')?.value;
    this.data.currency.maxWin = this.editingForm.get('maxWin')?.value;
    this.data.currency.predef1 = this.editingForm.get('predef1')?.value;
    this.data.currency.predef2 = this.editingForm.get('predef2')?.value;
    this.data.currency.predef3 = this.editingForm.get('predef3')?.value;
    this.data.currency.predef4 = this.editingForm.get('predef4')?.value;
    this.data.currency.predef5 = this.editingForm.get('predef5')?.value;
    this.data.currency.predef6 = this.editingForm.get('predef6')?.value;
    this.dialogRef.close({data: this.data.currency});
  }

  public get predef1(){
    return this.editingForm.get('predef1');
  }
  public get predef2(){
    return this.editingForm.get('predef2');
  }
  public get predef3(){
    return this.editingForm.get('predef3');
  }
  public get predef4(){
    return this.editingForm.get('predef4');
  }
  public get predef5(){
    return this.editingForm.get('predef5');
  }
  public get predef6(){
    return this.editingForm.get('predef6');
  }

}
